exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-slug-js": () => import("./../../../src/pages/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-mdx-slug-js" */),
  "component---src-pages-newofficial-js": () => import("./../../../src/pages/newofficial.js" /* webpackChunkName: "component---src-pages-newofficial-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-staff-mdx-slug-js": () => import("./../../../src/pages/staff/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-staff-mdx-slug-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

